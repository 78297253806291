import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    SHOW_SUCCESS,SHOW_ERROR,SHOW_ARTICLE_LIKEFOR_UPLOAD_DIALOG,
    HIDE_ARTICLE_LIKEFOR_UPLOAD_DIALOG, ARTICLELIKEFOR_GET_TAGS_FOR_MAPPING_LIST,
    ARTICLELIKEFOR_GET_GROUPING_BY_SKUS, ARTICLELIKEFOR_GET_RECOMMENDATION_TAGS_FOR_MAPPING_LIST,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_CLEAR_FILTER,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_FILTER,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_SCHEDULE,
    ARTICLELIKEFOR_GET_LIKEFORSIMILAR_COUNTRY_CARRIER,
    SHOW_BACKDROP_LOADER,
    SHOW_ARTICLE_LIKEFORUPGRADE_UPLOAD_DIALOG,
    HIDE_ARTICLE_LIKEFORUPGRADEUPLOAD_DIALOG
} from "./types";
import { handleError, showContentLoader, showBackDropLoader } from "../utils/utils";

export const batchCreateArticleLikeForMapping = (articleGroupings) => async dispatch => {
    console.log("Posting "+ JSON.stringify(articleGroupings) );
    axios.post(`${BASE_URL}/current/article/grouping/upload`, articleGroupings)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.fileUploaded',
                    dialogType: 'info',
                    refresh: true
                }
            })
        }).catch(error => {
            if (error.response.data.errorCode == "500" || error.response.data.errorCode == "400") {
                dispatch({
                    type: SHOW_ERROR,
                    payload:  { data: { translationKey: error.response.data.message?.replace(/:/g, '__') } } 
                })
            } else {
                handleError(error, dispatch)
            }
    })
}

export const showArticleLikeForUploadDialog = (entity, prepopulateField) => {
    return({
        type: SHOW_ARTICLE_LIKEFOR_UPLOAD_DIALOG,
        payload: {
            entity: entity,
            prepopulateField: prepopulateField
        }
    })
}

export const showArticleLikeUpgradeForUploadDialog = (entity, prepopulateField) => {
    return({
        type: SHOW_ARTICLE_LIKEFORUPGRADE_UPLOAD_DIALOG,
        payload: {
            entity: entity,
            prepopulateField: prepopulateField
        }
    })
}

export const hideArticleLikeForUpgradeUploadDialog = () => {
    return ({
        type: HIDE_ARTICLE_LIKEFORUPGRADEUPLOAD_DIALOG
    })
}

export const hideArticleLikeForUploadDialog = () => {
    return ({
        type: HIDE_ARTICLE_LIKEFOR_UPLOAD_DIALOG
    })
}

export const getArticleLikeForTagsForMapping = (countryCodes) => async dispatch => {
    console.log("Posting "+ JSON.stringify(countryCodes) );
    showContentLoader(true, dispatch);
    axios.post(`${BASE_URL}/current/article/tagForMappingByCountryCode/`, countryCodes)
        .then(response => {
            dispatch({
                type: ARTICLELIKEFOR_GET_TAGS_FOR_MAPPING_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        }
        )
}

export const getArticleGroupingBySkus = (skuList) => async dispatch => {
    console.log("Posting "+ JSON.stringify(skuList) );
    axios.post(`${BASE_URL}/current/article/grouping/`, skuList)
        .then(response => {
            dispatch({
                type: ARTICLELIKEFOR_GET_GROUPING_BY_SKUS,
                payload: response
            })
        }).catch(error => {
            showContentLoader(false, dispatch);
            if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
                handleError(error, dispatch);
            }
        })
}

export const getAutomatedTagsForMapping = (data) => async dispatch => {
    showBackDropLoader(true, dispatch);
    await axios.post(`${BASE_URL}/current/article/grouping/recommendation/`,data)
   .then(response => {
    dispatch({
        type: ARTICLELIKEFOR_GET_RECOMMENDATION_TAGS_FOR_MAPPING_LIST,
        payload: response
 
    })
    showBackDropLoader(false, dispatch);

   }).catch(error => {
        showBackDropLoader(false, dispatch);
        showContentLoader(false, dispatch);
        handleError(error)
    })
}

export const getArticleLikeForSimilarRecommendationUpdates = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/current/article/dailyRecommendation/?${path}`)
    .then(response => {
            console.log("response", response);
    dispatch({
        type: ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST,
        payload: response
    })
            showContentLoader(false, dispatch);
    }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
    
}

export const getArticleLikeForSimilarUpdateSchedule = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/config/${path}`)
    .then(response => {
            console.log("response", response);
    dispatch({
        type: ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_SCHEDULE,
        payload: response
    })
            showContentLoader(false, dispatch);
    }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter = (filterKey, filterValue) => {
    return({
        type: ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearArticleLikeForSimilarRecommendationUpdatesFilter = () => {
    return ({
        type: ARTICLELIKEFOR_GET_LIKEFORSIMILAR_RECOMMENDATION_UPDATES_LIST_CLEAR_FILTER
    })
}

export const updateArticleLikeForSimilarRecommendations = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/updateRecommendation`, data)
    .then(response => {
        console.log(response)
        dispatch({
            type: SHOW_SUCCESS,
            payload: {
                message: 'messages.articleLikeFor.updateSuccessful',
                dialogType: 'success'
            }
        }
        )
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const updateAllArticleLikeForSimilarRecommendations = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/updateAllRecommendation`, data)
    .then(response => {
        console.log(response)
        dispatch({
            type: SHOW_SUCCESS,
            payload: {
                message: 'messages.articleLikeFor.updateSuccessful',
                dialogType: 'success'
            }
        }
        )
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const refreshArticleLikeForSimilarRecommendations = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/refreshRecommendation`, data)
    .then(response => {
        console.log(response)
        dispatch({
            type: SHOW_SUCCESS,
            payload: {
                message: 'messages.articleLikeFor.refreshSuccessful',
                dialogType: 'refreshSuccess'
            }
        }
        )
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const getArticleLikeForSimilarCountryCarrier = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    try {
        let response = await axios.get(`${BASE_URL}/current/config/${path}`)

        console.log("response", response)
        
        dispatch({
            type: ARTICLELIKEFOR_GET_LIKEFORSIMILAR_COUNTRY_CARRIER,
            payload: response
        })
    } catch (error) {
        handleError(error, dispatch);
    }
    showContentLoader(false, dispatch);
}
